'use client';

import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import React from 'react';

export default function UserTypeTracker() {
  const user = useUser('cookieValue');
  React.useEffect(() => {
    // TODO: check if there is a better place for this,
    // at moment it's the only outbrain script that loads in homepage
    fork({
      default: undefined,
      htz: (() => {
        if (typeof window.obApi === 'function' && user.userType === 'paying') {
          window.obApi('track', 'subscriber-htz');
        }
      })(),
    });
  }, [user.userType]);

  return null;
}
