'use client';

import { usePathname } from 'next/navigation';
import React from 'react';

export default function usePathChanged(cb: (pathname: string) => void | (() => void)) {
  const pathname = usePathname();
  const prevPathnameRef = React.useRef<string | undefined>(undefined);

  React.useEffect(() => {
    if (pathname !== prevPathnameRef.current) {
      prevPathnameRef.current = pathname;

      let result: undefined | ReturnType<typeof cb> = undefined;

      if (typeof cb === 'function') {
        result = cb(pathname);
      }

      return result;
    }

    return undefined;
  }, [cb, pathname]);

  return null;
}
